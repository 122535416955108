<template>
<div class="h-screen flex w-full">
    <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
        <img src="@/assets/images/logo.png" class="mx-auto mb-2 max-w-full custom-logo-size">
        <h3 class="sm:mx-0 mx-4 mb-12 text-xl text-dark">Please wait while we logout</h3>
        <vs-button size="large" to="/home">Login</vs-button>
    </div>
</div>
</template>

<script>
import Store from "../../store/store";

export default {
    async created() {
        for (var obj in sessionStorage) {
            sessionStorage.removeItem(obj);
        }
        this.$store.dispatch("setIsProgressing", true);
        this.$store.dispatch("logout");
    }
}
</script>

<style scoped>
.custom-logo-size {
    width: 40rem;
}
</style>
